<template>
  <div class="mt-10">
    <div class="grid grid-rows-2">
      <label for="answer" class="mb-2" v-html="this.question"></label>
      <SwitchGroup as="div" class="flex justify-start items-center">
        <Switch
          v-model="answer"
          :class="[
            answer
              ? 'bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
              : 'bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200',
          ]"
          @click="$emit('addAnswer', answer, this.id)"
          :required="this.mandatory ? true : false"
        >
          <span
            :class="[
              answer ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            ]"
          >
            <span
              :class="[
                answer
                  ? 'opacity-0 ease-out duration-100'
                  : 'opacity-100 ease-in duration-200',
                'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
              ]"
              aria-hidden="true"
            >
              <svg class="h-3 w-3 text-red-400" fill="none" viewBox="0 0 12 12">
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span
              :class="[
                answer
                  ? 'opacity-100 ease-in duration-200'
                  : 'opacity-0 ease-out duration-100',
                'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
              ]"
              aria-hidden="true"
            >
              <svg
                class="h-3 w-3 text-green-600"
                fill="currentColor"
                viewBox="0 0 12 12"
              >
                <path
                  d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                />
              </svg>
            </span>
          </span>
        </Switch>
        <SwitchLabel as="span" class="ml-3">
          <label for="answer" v-html="this.consent"></label>
        </SwitchLabel>
      </SwitchGroup>
    </div>
    <div
      v-show="withPicture"
      class="mt-1 sm:mt-0 sm:col-span-2 flex justify-center"
    >
      <div
        class="w-1/2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-400 border-dashed rounded"
      >
        <div class="space-y-1 text-center">
          <svg
            class="mx-auto h-12 w-12 text-gray-500"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="flex text-sm text-gray-600">
            <label
              for="file-upload"
              class="relative cursor-pointer rounded font-medium text-black hover:text-gray-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-offset-2 focus-within:ring-white"
            >
              <span>Upload a file</span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                class="sr-only"
              />
            </label>
          </div>
          <p class="text-xs text-gray-600">
            PNG, JPG, GIF up to 10MB
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { Switch } from "@headlessui/vue";

export default {
  name: "Consent",
  props: ["question", "mandatory", "id"],
  components: {
    Switch,
  },
  data() {
    return {
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      consent: "I agree",
      withPicture: true,
    };
  },
  setup() {
    const answer = ref(false);

    return {
      answer,
    };
  },
};
</script>
