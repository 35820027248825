<template>
  <div class="mt-10">
    <label for="answer" v-html="this.question"></label>
    <div class="mt-3 inline-block min-w-full">
      <div class="inline-flex min-w-full">
        <input
          type="range"
          min="1"
          max="10"
          :required="this.mandatory ? true : false"
          name="answer"
          v-model="answer"
          id="answer"
          class="flex-1"
          @input="$emit('addAnswer', $event.target.value, this.id)"
        />

        <div v-if="answer < 5">
          <EmojiSadIcon
            :class="[
              answer > '2'
                ? 'ml-2 h-10 w-10 text-orange-500'
                : 'ml-2 h-10 w-10 text-red-500',
            ]"
            aria-hidden="true"
          />
        </div>
        <div v-else-if="answer >= 6">
          <EmojiHappyIcon
            :class="[
              answer < 9
                ? 'ml-2 h-10 w-10 text-green-500'
                : 'ml-2 h-10 w-10 text-blue-500',
            ]"
            aria-hidden="true"
          />
        </div>
        <div v-else>
          <QuestionMarkCircleIcon
            class="ml-2 h-10 w-10 text-gray-500"
            aria-hidden="true"
          />
        </div>
      </div>
      <p class="pr-10 mt-0 flex justify-center">{{ answer }}</p>
    </div>
  </div>
</template>

<script>
import {
  EmojiHappyIcon,
  EmojiSadIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/solid";
export default {
  name: "ScaleOneToTen",
  props: ["question", "mandatory", "id"],
  components: {
    EmojiHappyIcon,
    EmojiSadIcon,
    QuestionMarkCircleIcon,
  },
  data() {
    return { answer: "5" };
  },
};
</script>
