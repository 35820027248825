<template>
  <div class="mt-10">
    <h1 v-html="this.question"></h1>
    <input
      type="text"
      name="open-answer"
      :required="this.mandatory ? true : false"
      id="open-answer"
      class="shadow-sm block w-full sm:text-sm text-black border-gray-300 px-4 rounded-full"
      placeholder=""
      @input="$emit('addAnswer', $event.target.value, this.id)"
    />
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";

export default {
  name: "OpenQuestion",
  props: ["question", "mandatory", "id"],
  components: {},
  data() {
    return {
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        fillEmptyBlocks: false,
        tabSpaces: 0,
        placeholder: "Write your reply here...",
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
        ],
        toolbar: {
          items: ["heading", "bold", "italic", "|", "undo", "redo"],
        },
        language: "en",
        licenseKey: "",
      },
    };
  },
};
</script>

<style>
.ck {
  height: 25vh;
  color: black;
}
</style>
