<template>
  <div class="survey_page" v-show="this.surveyData.id && !this.surveyCompleted">
    <div class="survey_card">
      <p class="font-medium text-white mb-10 text-2xl">
        {{ this.surveyData.name }}
      </p>
      <div
        class="items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 card card-height"
      >
        <div class="flex flex-wrap pb-10 w-full">
          <progress-bar
            :percentage="this.getPercentage(this.surveyData.id)"
            class="mx-2 mb-2 h-5"
          >
            <span class="text-xs text-white w-full flex justify-end pr-2"
              >{{ this.getPercentage(this.surveyData.id) }}%</span
            >
          </progress-bar>
        </div>
        <div v-if="this.surveyData.id && !this.surveyCompleted">
          <form @submit.prevent="this.postAnswers(false)" method="POST">
            <div class="text-xl font-medium">
              <ul>
                <li v-for="question in this.surveyQuestions" :key="question.id">
                  <div v-if="question.questionType === 'openQuestion'">
                    <OpenQuestion
                      :question="question.question"
                      :mandatory="question.mandatoryReply"
                      :id="question.id"
                      @add-answer="(...args) => this.addAnswer(...args)"
                    />
                  </div>
                  <div v-if="question.questionType === 'scaleOneToFive'">
                    <ScaleOneToFive
                      :question="question.question"
                      :mandatory="question.mandatoryReply"
                      :id="question.id"
                      @add-answer="(...args) => this.addAnswer(...args)"
                    />
                  </div>

                  <div v-if="question.questionType === 'scaleOneToTen'">
                    <ScaleOneToTen
                      :question="question.question"
                      :mandatory="question.mandatoryReply"
                      :id="question.id"
                      @add-answer="(...args) => this.addAnswer(...args)"
                    />
                  </div>
                  <div v-if="question.questionType === 'consent'">
                    <Consent
                      :question="question.question"
                      :mandatory="question.mandatoryReply"
                      :id="question.id"
                      :withPicture="withPicture"
                      @add-answer="(...args) => this.addAnswer(...args)"
                    />
                  </div>
                  <div v-if="question.questionType === 'checkbox'">
                    <CheckMultipleAnswers
                      :question="question.question"
                      :mandatory="question.mandatoryReply"
                      :id="question.id"
                      @add-answer="(...args) => this.addAnswer(...args)"
                      type="checkbox"
                    />
                  </div>
                  <div v-if="question.questionType === 'multipleChoice'">
                    <CheckMultipleAnswers
                      :question="question.question"
                      :mandatory="question.mandatoryReply"
                      :id="question.id"
                      @add-answer="(...args) => this.addAnswer(...args)"
                      type="radio"
                    />
                  </div>
                  <div v-if="question.questionType === 'yesOrNo'">
                    <YesOrNo
                      :question="question.question"
                      :mandatory="question.mandatoryReply"
                      :id="question.id"
                      @add-answer="(...args) => this.addAnswer(...args)"
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div class="flex justify-between mt-5 text-center">
              <button
                @click.prevent="resetSurvey()"
                class="ml-10 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Reset
              </button>
              <button
                v-show="this.surveyData.id < this.groupQuestionLastId"
                type="submit"
                class="mr-10 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
              <button
                v-show="this.surveyData.id == this.groupQuestionLastId"
                @click.prevent="this.postAnswers(true)"
                class="mr-10 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Complete
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <p class="text-white p-10">{{ this.arrayAnswers }}</p> -->
  </div>
  <SurveyCompleted v-if="this.surveyCompleted" />
  <Error404
    v-if="this.error.status == 404"
    :msg="this.errorMsg"
    :status="this.error.status"
  />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import Error404 from "../components/Error404.vue";
import OpenQuestion from "../components/OpenQuestion.vue";
import ScaleOneToFive from "../components/ScaleOneToFive.vue";

import ScaleOneToTen from "../components/ScaleOneToTen.vue";
import Consent from "../components/Consent.vue";
import CheckMultipleAnswers from "../components/CheckMultipleAnswers.vue";
import YesOrNo from "../components/YesOrNo.vue";
import ProgressBar from "../components/ProgressBar.vue";
import SurveyCompleted from "../components/SurveyCompleted.vue";

export default {
  name: "Survey",
  props: ["surveyKey"],
  components: {
    Error404,
    OpenQuestion,
    ScaleOneToFive,
    ScaleOneToTen,
    Consent,
    CheckMultipleAnswers,
    YesOrNo,
    ProgressBar,
    SurveyCompleted,
  },
  data() {
    return {
      answer: "",
      file1: "",
      file2: "",
      surveyData: {},
      error: {},
      errorMsg: "",
      surveyQuestions: [],
      arrayAnswers: [],
      progressStart: 10,
      contentProgress: 20,
      groupQuestionLastId: 4,
      dataReply: [],
      surveyCompleted: false,
    };
  },
  methods: {
    addAnswer(...args) {
      this.arrayAnswers[args[1]] = args[0];
    },
    getPercentage(id) {
      return ((id - 1) / this.groupQuestionLastId) * 100;
    },

    handleFile1Upload() {
      this.file1 = this.$refs.file1.files[0];
    },
    handleFile2Upload() {
      this.file2 = this.$refs.file2.files[0];
    },
    getQuestions() {
      axios
        .get(
          `${this.$cookie.getCookie("API")}/api/v1/replyToSurveyRequests/${
            this.surveyKey
          }`
        )
        .then((response) => {
          this.surveyData = response.data;
          this.surveyQuestions = response.data.survey_questions;
        })
        .catch((error) => {
          this.error = error.response;
          this.errorMsg = error.response.data.response;
          console.error(error.response);
        });
    },

    postAnswers(isComplete) {
      let firstValueIndex = [];
      this.dataReply = [];

      for (let index = 0; index < this.arrayAnswers.length; index++) {
        if (this.arrayAnswers[index]) {
          firstValueIndex.push(index);
        }
      }

      for (
        let index = firstValueIndex[0];
        index < this.arrayAnswers.length;
        index++
      ) {
        let data = {
          reply: this.arrayAnswers[index],
          file: "",
          complement: "",
          id: index,
        };
        this.dataReply.push(data);
      }

      const options = {
        method: "POST",
        url: `${this.$cookie.getCookie(
          "API"
        )}/api/v1/replyToSurveyRequests?replyToSurveyRequestKey=${
          this.surveyKey
        }`,
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(this.dataReply),
      };

      axios
        .request(options)
        .then((response) => {
          if (!isComplete) {
            location.reload();
          }
          this.surveyCompleted = isComplete;
        })
        .catch(function(error) {
          this.errorHandling(error);
        });
    },
    resetSurvey() {
      axios
        .post(
          `${this.$cookie.getCookie(
            "API"
          )}/api/v1/replyToSurveyRequests/reset?replyToSurveyRequestKey=${
            this.surveyKey
          }`
        )
        .then((response) => {
          location.reload();
        })
        .catch((error) => {
          this.error = error.response;
          this.errorMsg = error.response.data.response;
          console.error(error.response);
        });
    },
  },
  mounted() {
    this.getQuestions();
  },
};
</script>

<style>
.survey_page {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../assets/paris_background.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 0;
}
.survey_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-width: 100rem;
  min-width: 70rem;
  /* border-radius: 10px; */
}
.card-height {
  min-height: 30vh;
}
.bg-gray-50 {
  background-color: rgba(255, 255, 255, 0.733);
}
</style>
