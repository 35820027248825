<template>
  <div class="bg-white min-h-screen flex flex-col lg:relative">
    <div class="flex-grow flex flex-col">
      <main class="flex-grow flex flex-col bg-white">
        <div
          class="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8"
        >
          <div class="flex-shrink-0 pt-10 sm:pt-16">
            <a href="/" class="inline-flex mt-10">
              <span class="sr-only">Encom</span>
              <img
                class="h-32 w-auto"
                src="../assets/encom-transp.png"
                alt=""
              />
            </a>
          </div>
          <div class="flex-shrink-0 my-auto">
            <p
              class="text-sm font-semibold text-indigo-600 uppercase tracking-wide"
            >
              Thank you
            </p>
            <h1
              class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl"
            >
              Survey completed
            </h1>
            <p class="mt-2 text-base text-gray-500">
              Your answers were computed.
            </p>
            <div class="mt-6">
              <a
                href="/"
                class="text-base font-medium text-indigo-600 hover:text-indigo-500"
                >Go back home<span aria-hidden="true"> &rarr;</span></a
              >
            </div>
          </div>
        </div>
      </main>
    </div>
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        src="../assets/paris_background.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SurveyCompleted",
};
</script>

<style></style>
